<template>
<section class="wrapper bg-light">
	<div class="container py-14 py-md-16">
		<div class="row gx-lg-8 gx-xl-12 gy-12 align-items-center  pb-6"
				 :class="css">
			<div class="col-lg-6 position-relative">
				<div class="btn btn-circle btn-primary disabled position-absolute counter-wrapper flex-column d-none d-md-flex"
						 style="top: 50%; left: 50%; transform: translate(-50%, -50%); width: 170px; height: 170px;">
					<h3 class="text-white mb-1 mt-n2"><span class="counter counter-lg">6%</span></h3>
					<p>2% + 1%</p>
				</div>
				<div class="row gx-md-5 gy-5 align-items-center">
					<div class="col-md-6">
						<div class="row gx-md-5 gy-5">
							<div class="col-md-10 offset-md-2">
								<figure class="rounded"><img src="@/assets/img/photos/ab1.jpg"
											 srcset="@/assets/img/photos/ab1@2x.jpg 2x"
											 alt=""></figure>
							</div>
							<!--/column -->
							<div class="col-md-12">
								<figure class="rounded"><img src="@/assets/img/photos/ab2.jpg"
											 srcset="@/assets/img/photos/ab2@2x.jpg 2x"
											 alt=""></figure>
							</div>
							<!--/column -->
						</div>
						<!--/.row -->
					</div>
					<!--/column -->
					<div class="col-md-6">
						<figure class="rounded"><img src="@/assets/img/photos/ab3.jpg"
									 srcset="@/assets/img/photos/ab3@2x.jpg 2x"
									 alt=""></figure>
					</div>
					<!--/column -->
				</div>
				<!--/.row -->
			</div>
			<!--/column -->
			<div class="col-lg-6">

				<h3 class="display-5 mb-5"
						v-html="home_sponsor1" />
				<p class="mb-7 lead"
					 v-html="home_sponsor2" />
				<p> <b v-html="home_sponsor3" /></p>

				<br />
				<div class="row counter-wrapper gy-6">
					<div class="col-md-4">
						<h3 class="counter text-primary">6%</h3>
						<p v-html="home_sponsor4" />
					</div>
					<!--/column -->
					<div class="col-md-4">
						<h3 class="counter text-primary">2%</h3>
						<p v-html="home_sponsor5" />
					</div>
					<div class="col-md-4">
						<h3 class="counter text-primary">1%</h3>
						<p v-html="home_sponsor7" />
					</div>
					<!--/column -->
					<div class="col-md-4"
							 v-if="link!=undefined">
						<a :href="link"
							 class="btn btn-sm btn-soft-ash rounded-pill btn-icon btn-icon-start mb-0"
							 v-html="home_sponsor6" />
					</div>
					<!--/column -->
				</div>
				<!--/.row -->
			</div>
			<!--/column -->
		</div>
		<!--/.row -->
	</div>
	<!-- /.container -->
</section>
<!-- /section -->
</template>


<script>
export default {
	name: 'fact3',
	components: {},
	props: {
		css: {
			type: String,
			default: "bg-pale-leaf"
		},
		home_sponsor1: String,
		home_sponsor2: String,
		home_sponsor3: String,
		home_sponsor4: String,
		home_sponsor5: String,
		home_sponsor6: String,
		home_sponsor7: String,
		link: String
	},
	data: () => ({}),
	beforeCreate: function () {},
	created: function () {},
	beforeMount: function () {},
	mounted: function () {},
	beforeUpdate: function () {},
	updated: function () {},
	beforeDestroy: function () {},
	destroyed: function () {},
	computed: {},
	methods: {},
	watch: {}
}
</script>

<style scoped>
</style>
