<template>
<div>

	<fact3 css=""
				 :home_sponsor1="$t('home_sponsor_1')"
				 :home_sponsor2="$t('home_sponsor_2')"
				 :home_sponsor3="$t('home_sponsor_3')"
				 :home_sponsor4="$t('home_sponsor_4')"
				 :home_sponsor5="$t('home_sponsor_5')"
				 :home_sponsor7="$t('home_sponsor_7')" />


	<!-- /section -->
	<section class="wrapper bg-gray   bg-xs-none    ">
		<div class="container pt-7 ">
			<div class="row">

				<div class="col-lg-6 position-relative pt-7">
					<h4 class="text-red "
							v-html="$t('yousponsor_up')" />
					<h2 class="display-4 mb-3"
							v-html="$t('yousponsor_titre')" />
					<p class="  ">
						<br />
						{{$t('yousponsor_line1')}}
						<br /> <br /><span v-html="$t('yousponsor_line2')"></span> <br /> <br /> {{$t('yousponsor_line3')}}
					</p>
					<p>
						<b> {{$t('staking_line4')}}</b><br />
						<br>(<i>{{$t('yousponsor_line4')}})</i>
					</p>
					<!--/column -->
				</div>
				<div class="col-lg-6">
					<v-img class="  rounded "
								 src="https://media.berlcoin.fr/26693.png"
								 alt="" />
				</div>
				<!--/column -->
			</div>
			<!-- /.row -->
		</div>
		<!-- /.container -->
	</section>




	<section class="wrapper  ">
		<div class="container py-14 py-md-16">
			<div class="row gx-lg-8 gx-xl-12 gy-12 align-items-center   pb-6">
				<div class="col-lg-6">
					<v-img class="  rounded "
								 src="https://media.berlcoin.fr/staking.jpg"
								 alt="" />
				</div>
				<!--/column -->
				<div class="col-lg-6">
					<h4 class="text-red"
							v-html="$t('staking_up')" />
					<h2 class="display-4 mb-3"
							v-html="$t('staking_title')" />
					<p class="lead"
						 style="max-width:620px;">
						<br />
						{{$t('staking_line1')}}
						<br />
						<br />
						{{$t('staking_line2')}}
						<br />
					</p>
					<p>
						{{$t('staking_line3')}}
						<br /> <br />
						<b>{{$t('staking_line4')}}</b> {{$t('staking_line5')}}
					</p>
					<br />

					<!--/.row -->
				</div>
				<!--/column -->
			</div>
			<!--/.row -->
		</div>
		<!-- /.container -->
	</section>

	<!-- /section -->
	<section class="wrapper bg-gray bg-cover bg-image bg-xs-none  py-15 ">
		<div class="container pt-7 pb-12 py-sm-12 py-xxl-12">
			<h4 class="text-red"
					v-html="$t('nftpatrimoine_up')" />
			<div class="row">

				<div class="col-lg-6 position-relative">
					<h2 class="display-4 mb-3"
							v-html="$t('nftpatrimoine_title')" />
					<p class="lead ">
						<br />
						{{$t('nftpatrimoine_line1')}}
						<br /> <br /> {{$t('nftpatrimoine_line2')}}
					</p>
					<p>
						{{$t('nftpatrimoine_line3')}}
					</p>
					<!--/column -->
				</div>
				<div class="col-lg-6">
					<v-img class="  rounded "
								 src="https://media.berlcoin.fr/BER0001x007.png"
								 alt="" />
				</div>
				<!--/column -->
			</div>
			<!-- /.row -->
		</div>
		<!-- /.container -->
	</section>




	<section class="wrapper  ">
		<div class="container py-14 py-md-16">
			<div class="row gx-lg-8 gx-xl-12 gy-12 align-items-center   pb-6">
				<div class="col-lg-6 position-relative">
					<img style="width:100%"
							 class="  rounded "
							 src="https://media.berlcoin.fr/nftsecurity.png"
							 alt="" />

				</div>
				<!--/column -->
				<div class="col-lg-6 position-relative">
					<h4 class="text-red">Actions en NFT </h4>
					<h2 class="display-4 mb-3"> NFT Security</h2>
					<p class="lead"
						 style="max-width:620px;">

						Les NFT Security sont des tokens correspondant à une action de la société qui posséde un
						bien spécifique.
						<br> <br> Les actions sont non nominatives, conservées par la holding et convertible en
						NFT Equity Stake.
						<br /> <br />
						NFT entièrement liquide, permet un achat et une revente sans délai et sans déclaration
						pour
						le vendeur, mais soumis à autorisation pour l’acheteur s'il n'est pas lui même
						actionnaire.
						<br /> <br />
						Plus-value : contrat de cession à 10 ans, montant fixé en Berlcoin lors de l'émission du
						NFT.
						<br /> <br /> Loyers: variable selon le bien immobilier


						<br />
					</p>

					<br />

					<!--/.row -->
				</div>
				<!--/column -->
			</div>
			<!--/.row -->
		</div>
		<!-- /.container -->
	</section>


	<!-- /section -->
	<section class="wrapper bg-gray bg-cover bg-image bg-xs-none  py-15 ">
		<div class="container pt-7 pb-12 py-sm-12 py-xxl-12">
			<h4 class="text-red">Actions nominatives </h4>
			<div class="row">

				<div class="col-lg-6 position-relative">
					<h2 class="display-4 mb-3"> NFT Equity Stake</h2>
					<p class="lead ">

						Les NFT Security sont des tokens correspondant à une action de la société qui posséde un
						bien spécifique.
						<br /> <br />
						Ces NFT sont nominatifs et ne sont cessibles qu'avec les actions attachées. Cette
						cession est soumise à autorisation pour le vendeur et pour l’acheteur s'il n'est pas lui
						même actionnaire.

						<br /> <br />
						Plus-value : en euros après la vente effective du bien.
						<br /> <br /> Loyers: variable selon le bien immobilier

						<br /> <br />
					</p>

					<!--/column -->
				</div>
				<div class="col-lg-6">
					<img style="width:100%"
							 class="  rounded "
							 src="https://media.berlcoin.fr/illustration-analyse-boursiere_52683-42095.png"
							 alt="" />
				</div>
				<!--/column -->
			</div>
			<!-- /.row -->
		</div>
		<!-- /.container -->
	</section>


	<section class="wrapper  ">
		<div class="container py-14 py-md-16">
			<div class="blog single mt-n17">
				<div class="card">

					<div class="card-body">
						<div class="classic-view">
							<article class="post">
								<div class="post-content mb-5">
									<h2 class="h1 mb-4"> Différence entre le Berlcoin et les 3 types de NFT
									</h2>
									<h3 class="h3 mb-4">Berlcoin</h3>
									<p>
										Le Berlcoin, c’est de l’immobilier liquide qui est adossé à un parc de
										biens immobiliers. La valeur du Berlcoin est indexée sur le VDP
										Indexprice. Les investisseurs peuvent donc acheter ou vendre du Berlcoin
										très facilement, sans frais et sans délai. Le rendement en 2022 à été de
										6.1% net.

									</p>
									<h3 class="h3 mb-4">NFT Patrimoine</h3>
									<p>
										Les NFT Patrimoine sont des contrats de staking, on gèle son
										investissement durant 10 et on reçoit une récompense de 46% à 55%.
									</p>
									<p>
										Les NFT Patrimoine ne peuvent être revendus sur des marchés
										décentralisés ou de gré à gré. Une fois passé sa date de libération (10
										ans), ils sont convertis en Berlcoin.
									</p>
									<p>
										Ces NFT restent un investissement mutualisé, mais avec une garantie
										spécifique sur un bien en particulier.
									</p>

									<blockquote class="fs-lg my-8">
										<p>Les Berlcoins et les NFT Patrimoines sont des tokens de la finance
											décentralisée, les NFT Security et Equity Stake sont des titres de
											sociétés immobilières tokenisés.</p>
										<footer class="blockquote-footer">Deux types de propriété immobilière fractionnée</footer>
									</blockquote>



									<h3 class="h3 mb-4">NFT Security</h3>
									<p>
										Les NFT Security sont des tokens directement liés à une action de la
										société qui possède le bien immobilier. Cette action est conservée par
										la holding qui en garantit le rachat au bout de 10 ans à un prix en
										Berlcoin défini dès le départ. Le prix final en euros est donc défini
										lui aussi par le VDP indexprice.
									</p>
									<p>
										Les investisseurs perçoivent les loyers en fonction des revenus réels de
										la société et peuvent revendre les NFT Security quand ils le désirent
										sous certaines conditions ( KYC validé de l’acheteur) et ils peuvent
										être transformés en NFT Equity Stake.
									</p>
									<h3 class="h3 mb-4">NFT Equity Stake</h3>
									<p>
										NFT Equity Stake sont des tokens directement liés à une action de la
										société qui possède le bien immobilier. Les investisseurs perçoivent les
										loyers en fonction des revenus réels de la société et peuvent revendre
										les actions et les NFT afférents dans les mêmes conditions légales d’une
										vente d'actions de société.
									</p>
									<p>
										Lorsque le bien est revendu, les investisseurs obtiennent une part de la
										vente au prorata de la détention d’action dans la société. C’est un
										produit immobilier classique tokenisé.
									</p>
									<p>
										Les NFT Equity Stake n’ont donc pas de la valeur légale intrinsèque, ce
										sont les actions attenantes qui sont le seul titre de propriété
										légalement reconnue.


									</p>




								</div>
								<!-- /.post-content -->

								<!-- /.post-footer -->
							</article>
							<!-- /.post -->
						</div>
						<!-- /.classic-view -->



						<!-- /.comment-form -->
					</div>
					<!-- /.card-body -->
				</div>
				<!-- /.card -->
			</div>
			<!-- /.blog -->
		</div>
		<!-- /column -->
	</section>




	<forminsert />
</div>
</template>


<script>
import fact3 from "@/components/fact3.vue";
import forminsert from "@/components/form_insert.vue";
export default {
	name: 'sponsoring',
	components: {
		fact3,
		forminsert
	},
	props: {

	},
	data: () => ({}),
	beforeCreate: function () {},
	created: function () {},
	beforeMount: function () {},
	mounted: function () {},
	beforeUpdate: function () {},
	updated: function () {},
	beforeDestroy: function () {},
	destroyed: function () {},
	computed: {},
	methods: {

		addkey() {
			document.getElementById("key").value = "0000000000";
			if (document.getElementById("check_new").checked) document.getElementById("check_new_line").style = "display:none;";
			else
				document.getElementById("check_new_line").style = "display:block;";
		},

	},
	watch: {}
}
</script>
